<script lang="ts" setup>
import type { DeepReadonly } from 'vue'

type Varation = {
  text: [string, string]
  color: string
  gradient: string
}

const variations = [
  {
    text: ['krea-', 'tiv'],
    color: '#5FF0D7',
    gradient: `linear-gradient(117deg, #D7FF28 0%, #5FF0D7 100%)`,
  },
  {
    text: ['des-', 'ign'],
    // color: '#E60032',
    color: '#5FF0D7',
    gradient: `linear-gradient(117deg, #E60032 0%, #881721 100%)`,
  },
  {
    text: ['agen-', 'tur'],
    // color: '#A4837A',
    color: '#5FF0D7',
    gradient: `linear-gradient(117deg, #B4968C 0%, #5A4242 100%)`,
  },
] as const satisfies DeepReadonly<Varation[]>

const variationIndex = ref(0)
const currentVariation = computed(() => variations[variationIndex.value])
const currentVariationDelayed = refDebounced(currentVariation, 600)

useInterval(3000, {
  callback: () => {
    variationIndex.value = ++variationIndex.value % variations.length
  },
})
</script>

<template>
  <div
    class="mx-auto mb-16 flex w-full max-w-container flex-col items-center gap-36 overflow-hidden pt-[15vh] lg:pt-[20vh] xl:pt-[25vh]"
  >
    <div class="max-w-screen relative flex h-2/3 w-full min-w-[400px] items-center justify-center">
      <div
        class="z-0 grid w-full [--col-size:1.75rem] [--row-size:2rem] sm:[--col-size:2.5rem] lg:[--col-size:4rem] lg:[--row-size:3.5rem]"
        :style="{
          gridTemplateColumns: '1fr repeat(4,var(--col-size)) 1fr',
          gridTemplateRows: 'repeat(8,var(--row-size))',
        }"
      >
        <!-- text top left -->
        <div
          class="flex w-min items-end justify-end place-self-end overflow-hidden px-8 max-md:!col-end-6 max-md:mb-4"
          style="
            clip-path: polygon(0 0, calc(100% - 2em) 0, 100% 100%, 2em 100%);
            grid-row: 1 / 4;
            grid-column: 1 / 3;
          "
        >
          <Transition
            enter-active-class="transition ease-out duration-[600ms]"
            enter-from-class="-translate-x-[110%]"
            enter-to-class="translate-x-0"
            leave-active-class="transition ease-in duration-[600ms]"
            leave-from-class="translate-x-0"
            leave-to-class="translate-x-[110%]"
            appear
            mode="out-in"
          >
            <span
              :key="currentVariation.text[0]"
              :style="{
                backgroundImage: currentVariation.gradient,
                backgroundSize: '70vw',
                backgroundPositionX: '0%',
              }"
              class="bg-clip-text text-7xl font-black italic text-transparent sm:text-8xl lg:text-[10rem] xl:text-[11rem]"
            >
              {{ currentVariation.text[0] }}
            </span>
          </Transition>
        </div>

        <!-- big ampersand -->
        <div
          class="relative -z-10 -ml-8 flex h-full w-full items-end justify-center place-self-center text-[200px] font-black italic leading-tight transition-all delay-[600ms] md:-ml-16 md:mb-28 md:text-[300px] lg:text-[400px]"
          style="grid-row: 3 / 9; grid-column: 2 / 6"
        >
          <div class="h-full w-full">
            <span
              class="bg-clip-text text-transparent"
              :style="{
                backgroundImage: currentVariationDelayed.gradient,
                backgroundSize: '50vw',
                backgroundPositionX: '0%',
              }"
            >
              &
            </span>
          </div>
          <div
            class="absolute z-10 h-full w-full -translate-y-2 translate-x-2 [-webkit-text-fill-color:transparent] [-webkit-text-stroke:0.005em_var(--outline-color)]"
            :style="{
              '--outline-color': currentVariation.color,
            }"
          >
            &
          </div>
        </div>

        <!-- leaf -->
        <NuxtImg
          class="-z-10 h-16 place-self-center justify-self-end md:h-24 lg:-mr-8 lg:mb-16 lg:h-48"
          style="grid-row: 5 / 9; grid-column: 1 / 4"
          src="/monstera-leaf-small.png"
          alt="blatt"
        />

        <!-- claim text -->
        <p
          class="self-center font-mono text-xs font-semibold max-lg:ml-4 max-md:mt-8 lg:text-xl lg:leading-7"
          style="grid-row: 2 / 5; grid-column: 6 / 7"
        >
          Die
          <br />
          Kreativagentur
          <br />
          am Elbstrom
          <br />
          in Dresden
        </p>

        <!-- text bottom right -->
        <div
          class="-mb-24 flex w-min self-end overflow-hidden px-8 max-lg:ml-16 max-md:!col-start-2 lg:-mb-8"
          style="
            clip-path: polygon(0 0, calc(100% - 2em) 0, 100% 100%, 2em 100%);
            grid-row: 6 / 9;
            grid-column: 6 / 7;
          "
        >
          <Transition
            appear
            enter-active-class="transition ease-out duration-[600ms]"
            enter-from-class="translate-x-[110%]"
            enter-to-class="translate-x-0"
            leave-active-class="transition ease-in duration-[600ms]"
            leave-from-class="translate-x-0"
            leave-to-class="-translate-x-[130%]"
            mode="out-in"
          >
            <span
              :key="currentVariation.text[1]"
              :style="{
                backgroundImage: currentVariation.gradient,
                backgroundSize: '70vw',
                backgroundPositionX: '90%',
              }"
              class="bg-clip-text pr-4 text-7xl font-black italic text-transparent sm:text-8xl lg:text-[10rem] xl:text-[11rem]"
            >
              {{ currentVariation.text[1] }}
            </span>
          </Transition>
        </div>
      </div>
    </div>
    <Padding class="text-center max-md:mt-[5vh]">
      <h1 class="sr-only">Elbworx</h1>
      <h2 class="text-3xl font-extrabold italic sm:text-4xl md:text-5xl">
        Klarheit
        <span class="text-2xl sm:text-3xl md:text-4xl">in</span>
        Konzept, Kreation
        <br class="hidden sm:block xl:hidden" />
        <span class="text-2xl sm:text-3xl md:text-4xl">und</span>
        Kommunikation
      </h2>
    </Padding>
  </div>
</template>
