<script lang="ts" setup>
import type { Logos } from '~/components/sections/PartnerLogos.vue'
import { PROJECTS_PAGE_ID } from '~/components/sections/ProjectSection.vue'

import { project as klassikDeluxe } from './projekt/klassik-deluxe.vue'
import { project as ocHa } from './projekt/oc-ha.vue'
import { project as startupCamp } from './projekt/startup-camp.vue'

useSeoMeta({
  title: 'elbworx | Die Kreativagentur am Elbstrom in Dresden',
  titleTemplate: '%s',
})

const homepageProjects = [
  [ocHa, 'fluid'],
  [startupCamp, 'left'],
  [klassikDeluxe, 'fluid'],
] as const

const customerLogos: Logos = [
  { src: 'novartis.svg' },
  { src: 'novo_nordisk.svg', img_class: 'md:p-3' },
  { src: 'alfasigma.svg' },
  { src: 'aphontis_pharma.svg' },
  { src: 'galapagos.svg' },
  { src: 'bibeltv.svg' },
  { src: 'ihk.svg' },
  { src: 'sh.svg', img_class: 'md:p-6 scale-150 mt-4 max-md:ml-12' },
  { src: 'maler_juergens.svg' },
  { src: 'ndrb.svg' },
  { src: 'coool_case.svg' },
  { src: 'licht_haus_halle.svg' },
  { src: 'stoffwechsel.svg', img_class: 'md:p-3' },
  { src: 'atelierluft.svg' },
  { src: 'von_lange_hand.jpeg', img_class: '' },
]
</script>

<template>
  <div :id="PROJECTS_PAGE_ID" class="flex flex-col items-center">
    <HomePageHeroSection />
    <Container class="relative isolate max-md:mb-16 md:mt-32">
      <div
        class="absolute z-10 mt-[calc(25%*var(--tilt-ratio))] flex w-full -translate-y-1/2 justify-end md:px-28"
      >
        <div
          class="mx-10 flex h-20 w-56 justify-between max-md:w-1/6 max-md:scale-75 md:mx-20 md:h-64"
        >
          <div v-for="i in 3" :key="i" class="w-0.5 rotate-[36deg] bg-elbworx-cyan md:w-1" />
        </div>
      </div>
      <Tilt contain class="bg-elbworx-black">
        <Padding>
          <Text class="mx-auto my-12 !max-w-[100ch] text-center md:my-48">
            <h3
              class="bg-[linear-gradient(80deg,theme('colors.elbworx-yellow'),theme('colors.elbworx-cyan'))] bg-clip-text !text-xl !font-extrabold !text-transparent sm:px-6 sm:!text-3xl/[1.3] md:px-0 md:!text-[3.5rem]/[1.1] md:!font-bold"
            >
              Zielgruppenorientierte Entwicklung von Marken und Kreativ-Konzepten. Design für Print,
              Online und Social&NonBreakingSpace;Media.
            </h3>
          </Text>
        </Padding>
      </Tilt>
    </Container>
    <Container class="-mt-tilt gap-10">
      <ProjectSection
        v-for="[project, alignment] of homepageProjects"
        :key="project.slug"
        :alignment="alignment"
        :project="project"
        class="-mt-tilt first:mt-0"
      />
      <div class="mt-2 grid place-items-center md:mt-24">
        <AnimatedButton href="/projekte">Weiter zu Projekten</AnimatedButton>
      </div>
    </Container>
    <!-- <Padding class="mt-24 md:mt-56">
      <Container class="items-center">
        <Text class="mb-12 max-md:prose-headings:text-2xl">
          <h3>Was denkst Du über Klarheit?</h3>
        </Text>
        <div class="flex flex-col items-center">
          <p
            class="max-w-[30ch] text-center text-base font-semibold italic text-elbworx-black/30 md:text-4xl"
          >
            „Für mich ist klare Kommunikation: Zu sagen, was gesagt werden muss, wenn es aus
            tiefster Überzeugung kommt und Deinen Werten entspricht. Aber eben auch in der Sache
            kritisch und Menschen gegenüber tolerant zu sein.“
          </p>
          <div
            class="mt-4 bg-elbworx-black px-2 pt-0.5 font-mono text-base font-medium text-white md:mt-12 md:pt-1.5 md:text-2xl"
          >
            Robert Rethberg
          </div>
        </div>
      </Container>
    </Padding> -->

    <PartnerLogos
      text="Ein Überblick unserer Zusammenarbeit"
      :logos="customerLogos"
      grid-class="md:grid-cols-3 xl:grid-cols-5"
      class="mt-24 md:mt-56"
    />

    <ContactTeaser />
  </div>
</template>
